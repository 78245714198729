import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Otzyv = () => (
  <Layout>
    <Seo title="Отзывы" />
    <div className="py-10 px-4 md:px-0">
      <h1 className="text-2xl font-semibold">Отзывы</h1>
      <div className="py-4">
        <div
          style={{
            width: "560px",
            height: "800px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <iframe
            title="un"
            style={{
              width: "100%",
              height: "100%",
              border: "1px solid #e6e6e6",
              borderRadius: "8px",
              boxSizing: "border-box",
            }}
            src="https://yandex.ru/maps-reviews-widget/120377155729?comments"
          />
          <a
            href="https://yandex.ru/maps/org/krasilnikov_frolov_i_partnery/120377155729/"
            target="_blank"
            rel="noreferrer"
            style={{
              boxSizing: "border-box",
              textDecoration: "none",
              color: "#b3b3b3",
              fontSize: "10px",
              padding: "0 20px",
              position: "absolute",
              bottom: "8px",
              width: "100%",
              textAlign: "center",
              left: "0",
            }}
          >
            Красильников, Фролов и партнеры на карте Казани — Яндекс.Карты
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default Otzyv
